/* @ngInject */
export default class DisplayChannelToggleController {
  constructor($rootScope) {
    this._$rootScope = $rootScope;
  }

  updateToggle(channelNumber, channelValue) {
    // Update the channel value
    this.channel.display = channelValue;
    // If no toggles are left on, turn the current channel toggle on
    const atLeastOneToggleOn = this.allChannels.some((channel) => {
      return channel.display;
    });
    this.channel.display = channelValue || !atLeastOneToggleOn;

    // Change the channel value in the viewer config
    let leadIdentifier;
    switch (channelNumber) {
      case 1:
        leadIdentifier = "I";
        break;
      case 2:
        leadIdentifier = "II";
        break;
      case 3:
        leadIdentifier = "III";
        break;
      default:
    }

    this.ecgStripViewerConfig.displayElements.leads[leadIdentifier] = this.channel.display;

    // If we are turning off a display and that lead is displayed on the sequence viewer
    if (!this.channel.display && this.selectedStrip.sequenceLead === leadIdentifier) {
      // Move the displayed sequence viewer lead to the "next" available lead
      this._shiftStripSequenceLeadToNextEnabledLead(leadIdentifier);
    }

    this._$rootScope.$emit("viewer-settings-updated");
  }

  _shiftStripSequenceLeadToNextEnabledLead(leadIdentifier) {
    const stripLeads = this.ecgStripViewerConfig.displayElements.leads;

    switch (leadIdentifier) {
      case "I":
        if (stripLeads.II) {
          this.selectedStrip.sequenceLead = "II";
        } else {
          this.selectedStrip.sequenceLead = "III";
        }
        break;
      case "II":
        if (stripLeads.III) {
          this.selectedStrip.sequenceLead = "III";
        } else {
          this.selectedStrip.sequenceLead = "I";
        }
        break;
      case "III":
        if (stripLeads.I) {
          this.selectedStrip.sequenceLead = "I";
        } else {
          this.selectedStrip.sequenceLead = "II";
        }
        break;
      default:
    }
  }
}
