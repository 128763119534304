import isEmpty from "lodash/isEmpty";
import moment from "moment";

/* @ngInject */
export default class StripToolsController {
  constructor($rootScope, $scope, Config, StripService, Markers) {
    this._$rootScope = $rootScope;
    this._$scope = $scope;
    this.features = Config.features;
    this._StripService = StripService;
    this._Markers = Markers;

    this.$onInit = this._init;
  }

  /// Public Functions ///

  get selectedStrip() {
    // Inside the stripViewer, ecgEventItem is the currentStrip
    let selectedStrip = this.ecgEventItem;
    if (this.ecgEventItem.eventStrips && this.ecgEventItem.eventStrips.length > 0) {
      // Inside an ecgEventItem, eventStrips is an array.
      selectedStrip = this.ecgEventItem.eventStrips.find((savedStrip) => savedStrip.isSelected);
      if (!selectedStrip) {
        [selectedStrip] = this.ecgEventItem.eventStrips;
      }
    }
    return selectedStrip;
  }

  // This is required in the pug file to pass the selected strip through the directives
  getSelectedStrip() {
    return this.selectedStrip;
  }

  getFormattedMeasurement(measurement) {
    const {selectedStrip} = this;

    const newMeasurement = {
      name: measurement.name,
      stripId: selectedStrip.id,
      facilityId: selectedStrip.facilityId,
      startSampleOffset: measurement.startSampleOffset,
      endSampleOffset: measurement.endSampleOffset,
      startTime: measurement.startTime,
      endTime: measurement.endTime,
    };

    return newMeasurement;
  }

  addMeasurementForStrip(measurement) {
    const {selectedStrip} = this;
    const newMeasurement = this.getFormattedMeasurement(measurement);

    // Add measurement details and push on to data array for that measurementType
    selectedStrip.measurements[newMeasurement.name].data.push(newMeasurement);
    this.updateMinMeanMaxForStrip(newMeasurement.name);
  }

  deleteMeasurementForStrip(measurementToDelete) {
    if (measurementToDelete.id) {
      const {selectedStrip} = this;
      // measurementsToDelete are only utilized by the stripViewer.saveChangesToStrip
      if (!selectedStrip.measurementsToDelete) {
        selectedStrip.measurementsToDelete = [];
      }
      selectedStrip.measurementsToDelete.push(measurementToDelete.id);
    }

    const {selectedStrip} = this;
    // Find the index of the measurement based on values (start and end offsets)
    const indexToRemove = selectedStrip.measurements[measurementToDelete.name].data.findIndex(
      (measurement) => {
        return (
          measurement.startTime === measurementToDelete.startTime &&
          measurement.endTime === measurementToDelete.endTime
        );
      }
    );

    // Delete measurement from array
    selectedStrip.measurements[measurementToDelete.name].data.splice(indexToRemove, 1);
    this.updateMinMeanMaxForStrip(measurementToDelete.name);
  }

  updateMinMeanMaxForStrip(measurementType, strip = {}) {
    if (isEmpty(strip)) {
      strip = this.selectedStrip;
    }
    this._Markers.updateMinMeanMaxForStrip(measurementType, strip);
  }

  /**
   * Updates the selected strip in the strips array with a new value for the given field
   * @param {String} field to update
   * @param {Any} newValue
   */
  updateSelectedStrip(field, newValue) {
    this.selectedStrip[field] = newValue;
  }

  viewerSettingsUpdated() {
    this.selectedStrip.timeBase = this.ecgData.mmPerSecond;
    this.selectedStrip.gain = this.ecgData.mmPerMillivolt;

    this._$rootScope.$emit("viewer-settings-updated");
  }

  /// Private Functions ///

  _init() {
    if (this.ecgEventItem.ecg) {
      this.eventEcg = this.ecgEventItem.ecg;
    } else {
      this.eventEcg = this.ecgEventItem.event.ecg;
    }

    const deregister = this._$rootScope.$on("strip-viewer-updated", () => {
      const startTime = moment(this.eventEcg.startTime)
        .add(this.eventEcg.startStripSample / this.eventEcg.samplesPerSecond, "seconds")
        .toISOString();
      const endTime = moment(this.eventEcg.startTime)
        .add(this.eventEcg.endStripSample / this.eventEcg.samplesPerSecond, "seconds")
        .toISOString();

      const {selectedStrip} = this;
      selectedStrip.startTime = startTime;
      selectedStrip.endTime = endTime;
      selectedStrip.middleSample = this._StripService.getStripMidpoint(selectedStrip, this.eventEcg);
    });
    this._$scope.$on("$destroy", deregister);

    const deregisterAddMeasurement = this._$rootScope.$on("measurement-created", (emittedEvent, marker) => {
      this.addMeasurementForStrip(marker);
    });
    this._$scope.$on("$destroy", deregisterAddMeasurement);

    const deregisterDeleteMeasurement = this._$rootScope.$on(
      "measurement-deleted",
      (emittedEvent, marker) => {
        this.deleteMeasurementForStrip(marker);
      }
    );
    this._$scope.$on("$destroy", deregisterDeleteMeasurement);

    const deregisterUpdateMinMeanMax = this._$rootScope.$on("update-min-mean-max", (emittedEvent, strip) => {
      this._Markers.MEASUREMENT_TYPES.forEach((measurementType) => {
        this.updateMinMeanMaxForStrip(measurementType, strip);
      });
    });
    this._$scope.$on("$destroy", deregisterUpdateMinMeanMax);
    this._$rootScope.$emit("update-min-mean-max", this.selectedStrip);
  }
}
