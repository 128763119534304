/* eslint-env browser */

/* @ngInject */
export default class Facility {
  constructor($injector, Config) {
    this._$http = $injector.get("$http");
    this._Config = Config;
    this._$injector = $injector;
    this.features = this._Config.features;
    this._Authentication = $injector.get("Authentication");
  }

  getLogoFilename(facilityId = this._Authentication.getFacilityId()) {
    return this._$injector
      .get("$http")({
        method: "GET",
        url: `${this._Config.apiUrl}/facilities/logo/filename/${facilityId}`,
      })
      .then((response) => response.data.logoFilename);
  }

  /**
   * This function takes in a facilityId that might contain underscores and creates all of the ids for its ancestor facilities
   * @param {String} facilityId
   * @return {Array}
   */
  calculateAncestorFacilityIds(facilityId) {
    const ids = facilityId.split("_");
    return ids.map((id, i) => ids.slice(0, i + 1).join("_"));
  }

  getLogoFile(filename) {
    return this._$injector
      .get("$http")({
        method: "GET",
        url: `${this._Config.apiUrl}/facilities/logo/${filename}`,
        headers: {
          "Content-type": "application/json",
        },
        responseType: "arraybuffer",
      })
      .then(({data}) => {
        const typedArray = new Uint8Array(data);
        const stringChar = String.fromCharCode.apply(null, typedArray);
        const base64String = btoa(stringChar);

        return _fixSVGEncoding(base64String, this.getLogoFileExtension(filename));
      });
  }

  getLogoFileExtension(filename) {
    const filenameSplit = filename.split(".");
    let extension = filenameSplit[filenameSplit.length - 1].toUpperCase();

    if (extension === "SVG") {
      extension = "svg+xml";
    }
    return extension;
  }

  getFacilityStudyConfigurations(facilityId) {
    const url = "/facilityStudyConfigurations";
    const query = {facilityId};
    return this.httpGet(url, query).then((response) => {
      if (!response.data || response.data.length === 0) {
        console.error(`Unable to fetch Facility Study Configurations for: ${facilityId}`);
        return null;
      }
      return response.data[0];
    });
  }

  httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {Authorization: authHeader},
      params,
    });
  }
}

function _fixSVGEncoding(image, fileExtension) {
  let result = image;
  if (fileExtension === "svg+xml") {
    try {
      const originalFile = atob(image);
      const originalXML = new DOMParser().parseFromString(originalFile, "text/xml");
      const originalSVG = originalXML.getElementsByTagName("svg")[0];

      if (originalSVG.getAttribute("height") === null && originalSVG.getAttribute("width") === null) {
        // Set the height of SVG from the viewBox if there is no height or width
        // (affects originalXML via nested reference)
        originalSVG.setAttribute("height", `${originalSVG.viewBox?.baseVal?.height || 50}px`);
        originalSVG.setAttribute("width", `${originalSVG.viewBox?.baseVal?.width || 50}px`);
        const updatedFile = new XMLSerializer().serializeToString(originalXML);
        result = btoa(updatedFile);
      }
    } catch (e) {
      result = image;
    }
  }
  return result;
}
