/* @ngInject */
export default class InvertChannelButtonController {
  constructor($rootScope) {
    this._$rootScope = $rootScope;
  }

  invertChannel(channelNumber) {
    this.channel.invert = !this.channel.invert;
    switch (channelNumber) {
      case 1:
        this.ecgStripViewerConfig.inversionStatus.leads.I = this.channel.invert;
        this.sequenceViewerConfig.inversionStatus.leads.I = this.channel.invert;
        break;
      case 2:
        this.ecgStripViewerConfig.inversionStatus.leads.II = this.channel.invert;
        this.sequenceViewerConfig.inversionStatus.leads.II = this.channel.invert;
        break;
      case 3:
        this.ecgStripViewerConfig.inversionStatus.leads.III = this.channel.invert;
        this.sequenceViewerConfig.inversionStatus.leads.III = this.channel.invert;
        break;
      default:
        break;
    }

    this._$rootScope.$emit("viewer-settings-updated");
  }

  getIconName(inverted) {
    // display invert icon
    let iconName = "arrow_downward";
    if (inverted) {
      // display un-invert icon
      iconName = "arrow_upward";
    }
    return iconName;
  }

  getTooltip(inverted) {
    // display invert message
    let tooltip = "Invert Channel";
    if (inverted) {
      // display un-invert message
      tooltip = "Revert Channel";
    }
    return tooltip;
  }
}
