import angular from "angular";
/* @ngInject */
export default class EcgViewerController {
  constructor($scope, $rootScope, $element, EcgViewerFactory, EcgNavigation) {
    this._$element = $element;
    this._$scope = $scope;
    this._$rootScope = $rootScope;
    this._EcgViewerFactory = EcgViewerFactory;
    this._EcgNavigation = EcgNavigation;

    this.$onInit = this._init;
    $scope.$on("$destroy", () => {
      this.ecgData.startStripSample = this.originalStartStripSample;
      this.ecgData.endStripSample = this.originalEndStripSample;
      this.ecgData.mmPerMillivolt = this.originalMmPerMv;
      this.ecgData.mmPerSecond = this.originalMmPerS;
    });
  }

  _init() {
    this.originalMmPerMv = this.ecgData.mmPerMillivolt;
    this.originalMmPerS = this.ecgData.mmPerSecond;
    this.originalStartStripSample = this.ecgData.startStripSample;
    this.originalEndStripSample = this.ecgData.endStripSample;
    const {viewerConfig, ecgData, allowEcgNavigationScroll, navBoxes, beatMarkers, aspectRatio, parentType} =
      this;
    if (this.ecgData && !this.ecgData.error) {
      angular.element(() => {
        const ecgViewer = new this._EcgViewerFactory(
          this._$scope,
          viewerConfig,
          ecgData,
          this._$element[0],
          allowEcgNavigationScroll,
          navBoxes,
          beatMarkers,
          aspectRatio,
          parentType
        );
        this._EcgNavigation.createLink(ecgData, ecgViewer);
      });
    }
  }
}
