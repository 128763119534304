/* eslint-env browser */
import {DateTime} from "luxon";
import queryString from "qs";

import axios from "../axiosClient.js";

/* @ngInject */
export default class GeneratedReportService {
  constructor($injector, Config) {
    this._Config = Config;
    this._$injector = $injector;
    this._Authentication = $injector.get("Authentication");
    this._$http = $injector.get("$http");
    this._StripService = $injector.get("StripService");
    this.features = this._Config.features;
  }

  generateSingleEpisodeReport(
    ecgEventItem,
    stripsToInclude,
    beatMarkers,
    logoFilename,
    comment = "",
    meetsMdnCriteria = false
  ) {
    stripsToInclude = this._sanitizeStrips(stripsToInclude);
    const report = {
      comment,
      reportType: "Single Episode",
      facilityName: ecgEventItem.facilityName,
      facilityId: ecgEventItem.facilityId,
      tzSerial: ecgEventItem.tzSerial,
      studyId: ecgEventItem.studyId,
      studyType: ecgEventItem.studyType,
      itemId: ecgEventItem.id,
      eventClassification: ecgEventItem.userClassification,
      strips: stripsToInclude,
      meetsMdnCriteria,
      beatMarkers,
      logoFilename,
      timestamp: DateTime.utc().toFormat("yyyy-MM-dd hh:mm:ssZZ"),
    };
    const url = "/generatedReports";
    return this._httpPut(url, report);
  }

  generateReport(reportItem, stripsToInclude, beatMarkers, logoFilename, comment, meetsMdnCriteria = false) {
    stripsToInclude = this._sanitizeStrips(stripsToInclude);
    const report = {
      comment,
      reportType: reportItem.type,
      facilityId: reportItem.facilityId,
      facilityName: reportItem.facilityName,
      tzSerial: reportItem.tzSerial,
      studyId: reportItem.studyId,
      studyType: reportItem.studyType,
      itemId: reportItem.id,
      strips: stripsToInclude,
      meetsMdnCriteria,
      beatMarkers,
      heartRateTrend: reportItem.heartRateTrend,
      pvcBurden: reportItem.pvcBurden,
      arrhythmiaData: reportItem.arrhythmiaData,
      ventricularEctopy: reportItem.ventricularEctopy,
      chartToggles: reportItem.chartToggles,
      logoFilename,
      timestamp: DateTime.utc().toFormat("yyyy-MM-dd hh:mm:ssZZ"),
      qrsExclusionRegions: this._sanitizeArtifactRegions(reportItem.artifactRegions),
    };
    const url = "/generatedReports";
    return this._httpPut(url, report);
  }

  getGeneratedReport(id) {
    const url = `/generatedReports/${id}`;
    return this._httpGet(url).then((response) => response.data);
  }

  getGeneratedReports(params = {}) {
    const url = "/generatedReports";

    return this._httpGet(url, params).then((response) => {
      return {reports: response.data, totalCount: response.headers("count")};
    });
  }

  async updateReport(reportId, propertiesToUpdate) {
    const {data} = await axios({
      method: "patch",
      url: `/generatedReports/${reportId}`,
      data: propertiesToUpdate,
    });
    return data;
  }

  async saveChangesToReport(reportId, propertiesToUpdate) {
    if (propertiesToUpdate.strips) {
      propertiesToUpdate.strips = this._sanitizeStrips(propertiesToUpdate.strips);
    }
    if (propertiesToUpdate.artifactRegions) {
      propertiesToUpdate.qrsExclusionRegions = propertiesToUpdate.artifactRegions;
      delete propertiesToUpdate.artifactRegions;
    }
    if (propertiesToUpdate.qrsExclusionRegions) {
      propertiesToUpdate.qrsExclusionRegions = this._sanitizeArtifactRegions(
        propertiesToUpdate.qrsExclusionRegions
      );
    }
    const {data} = await axios({
      method: "put",
      url: `/generatedReports`,
      data: {id: reportId, ...propertiesToUpdate},
    });
    return data;
  }

  async publishReportPdf(reportId, fileBuffer) {
    const formData = new FormData();
    const file = new Blob([fileBuffer]);
    formData.append("file", file);

    const {data} = await axios({
      method: "post",
      url: `/generatedReports/pdf/${reportId}`,
      data: formData,
    });
    return data;
  }

  async signReportPdf(reportId, fileBuffer, body) {
    const formData = new FormData();
    const file = new Blob([fileBuffer]);
    formData.append("file", file);

    if (body) {
      Object.entries(body).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }

    const {data} = await axios({
      method: "post",
      url: `/generatedReports/sign/${reportId}`,
      data: formData,
    });
    return data;
  }

  _sanitizeStrips(strips) {
    return strips.map((strip) => {
      this._StripService.sanitizeMeasurementsOnStrip(strip);
      const stripMeasurements = strip.measurements;

      this._StripService.sanitizeStrip(strip);
      strip.measurements = stripMeasurements;
      delete strip.event;
      delete strip.includeInReport;

      return strip;
    });
  }

  _sanitizeArtifactRegions(regions) {
    return regions.map((region) => {
      return {
        startTime: new Date(region.startTime),
        endTime: new Date(region.endTime),
      };
    });
  }

  _httpGet(url, params, options = {}) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    options.headers = {
      Authorization: authHeader,
    };
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, options);
  }

  _httpPost(url, report) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.post(`${baseUrl}${url}`, report, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpPut(url, report) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.put(`${baseUrl}${url}`, report, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
