/**
 * Singleton service with methods for drawing navigation box and linking viewers
 */
import leadSelectMenuPug from "./leadSelectMenu.pug";

/* @ngInject */
export default class EcgNavigation {
  constructor($injector) {
    this._$document = $injector.get("$document");
    this._$window = $injector.get("$window");
    this._$rootScope = $injector.get("$rootScope");
    this._Drawing = $injector.get("Drawing");
    this._Config = $injector.get("Config");
    this.features = this._Config.features;
    this._$mdDialog = $injector.get("$mdDialog");
    this.defaultBoxColor = "rgba(0, 0, 100, 0.2)";
    this.viewerLinks = [];
    this.dragMode = false;
    this.keyPressedStates = {};
    this.mouseOffsetX = 0;
    this.mouseDownX = null;
    this.hasMouseMoved = false;
  }

  /**
   * Creates a link object for viewers with the same ECG ID
   *
   * @param {Object} ecgData ECG data for the viewers to share
   * @param {EcgViewer} viewer EcgViewer class instance to add to the link object
   */
  createLink(ecgData, viewer) {
    const {enrollmentId, sequence} = ecgData;
    let viewers = this._getViewersByEcg(enrollmentId, sequence);
    const {type} = viewer.config;

    if (!viewers) {
      const viewerLink = {enrollmentId, sequence, viewers: {}};
      ({viewers} = viewerLink);
      this.viewerLinks.push(viewerLink);
    }

    viewers[type] = viewer;
  }

  /**
   * Draws navigation box on the given canvas
   *
   * @param {EcgViewer}   ecgViewer       - the ecg viewer to draw the box on
   * @param {number}      middleX         - the middle pixel of the navigation box
   * @param {number}      secondsWide     - the number of seconds wide the navigation box should be
   */
  drawNavigationBox(ecgViewer, middleX, secondsWide) {
    const {startX, endX} = this._getNavigationBoxBoundaries(ecgViewer, middleX, secondsWide);

    this._Drawing.drawBox(ecgViewer.navigationCanvas, startX, endX);
  }

  /**
   * Draws navigation boxes on the given canvas
   *
   * @param {EcgViewer}   ecgViewer       - the ecg viewer to draw the box on
   * @param {Array}       boxes           - an array of middle pixel values and the number of seconds wide for the navigation box
   */
  drawNavigationBoxes(ecgViewer, boxes) {
    const endpoints = boxes.map((box) => {
      const {startX, endX} = this._getNavigationBoxBoundaries(ecgViewer, box.middleX, box.secondsWide, true);
      const convertedBox = {
        startX,
        endX,
        order: box.order,
        isSelected: box.isSelected,
        id: box.id,
        editing: !box.id || box.editing,
      };

      if (!ecgViewer.allowEcgNavigationScroll) {
        convertedBox.isGeneratedReport = true;
        convertedBox.isSelected = ecgViewer.inEditingMode;
      }

      return convertedBox;
    });
    this._Drawing.drawBoxes(ecgViewer.navigationCanvas, endpoints);
  }

  registerScrollEvent(ecgViewer, boxes = []) {
    const wheelCanvas =
      ecgViewer.config.type === "strip" ? ecgViewer.markersCanvas : ecgViewer.navigationCanvas;
    wheelCanvas.addEventListener(
      "wheel",
      (ecgViewer._wheel = (event) => this._wheelEvent(event, ecgViewer, boxes)),
      false
    );
  }

  deregisterScrollEvent(ecgViewer) {
    const wheelCanvas =
      ecgViewer.config.type === "strip" ? ecgViewer.markersCanvas : ecgViewer.navigationCanvas;
    wheelCanvas.removeEventListener("wheel", ecgViewer._wheel, false);
  }

  registerMouseEvents(ecgViewer, boxes = []) {
    const {navigationCanvas} = ecgViewer;
    navigationCanvas.addEventListener(
      "mousedown",
      (ecgViewer._mouseDown = (event) => this._mouseDownEvent(event, ecgViewer, boxes))
    );
    navigationCanvas.addEventListener(
      "click",
      (ecgViewer._click = (event) => this._clickEvent(event, ecgViewer, boxes))
    );
    navigationCanvas.addEventListener(
      "contextmenu",
      (ecgViewer._rightClick = (event) => this._rightClickEvent(event, ecgViewer, boxes))
    );
    this._$document[0].addEventListener(
      "mousemove",
      (ecgViewer._mouseMove = (event) => this._mouseMoveEvent(event, ecgViewer, boxes)),
      false
    );
    navigationCanvas.addEventListener(
      "dragstart",
      (ecgViewer._dragStart = (event) => this._dragStartEvent(event))
    );
    this._$document[0].addEventListener(
      "mouseup",
      (ecgViewer._mouseUp = () => this._mouseUpEvent(ecgViewer))
    );
    this._$window.onkeyup = (event) => {
      this.keyPressedStates[event.code] = false;
    };
    this._$window.onkeydown = (event) => {
      this.keyPressedStates[event.code] = true;
    };
  }

  deregisterMouseEvents(ecgViewer) {
    const {navigationCanvas} = ecgViewer;
    navigationCanvas.removeEventListener("mousedown", ecgViewer._mouseDown);
    navigationCanvas.removeEventListener("click", ecgViewer._click);
    navigationCanvas.removeEventListener("contextmenu", ecgViewer._rightClick);
    this._$document[0].removeEventListener("mousemove", ecgViewer._mouseMove, false);
    navigationCanvas.removeEventListener("dragstart", ecgViewer._dragStart);
    this._$document[0].removeEventListener("mouseup", ecgViewer._mouseUp);
    this._$window.onkeyup = null;
    this._$window.onkeydown = null;
  }

  getXCoordFromSample(ecgViewer, sample) {
    const totalPixels = ecgViewer.width;
    const totalSamples = ecgViewer.ecgData.samplesPerSecond * ecgViewer.secondsToDisplay;
    const pixelsPerSample = totalPixels / totalSamples;
    const sampleNumberInViewer = sample - ecgViewer.startSample;

    return sampleNumberInViewer * pixelsPerSample;
  }

  getFullViewer(enrollmentId, sequence) {
    return this._getViewersByEcg(enrollmentId, sequence).full;
  }

  getSequenceViewer(enrollmentId, sequence) {
    return this._getViewersByEcg(enrollmentId, sequence).sequence;
  }

  _wheelEvent(event, ecgViewer, boxes = []) {
    if (!event.shiftKey) {
      return false;
    }

    const updatedBox = boxes.find((box) => box.isSelected);

    // Strip is NOT editable if this is an ECG Event and the box already has an ID
    if (ecgViewer.allowEcgNavigationScroll && updatedBox.id && !updatedBox.editing) {
      return false;
    }

    let scrollDirection;

    if (event.deltaY > 0) {
      scrollDirection = "down";
    } else {
      scrollDirection = "up";
    }

    switch (ecgViewer.config.type) {
      case "strip": {
        const scrollAmount = ecgViewer.mmHorizontal;
        const sequenceViewer = this.getSequenceViewer(
          ecgViewer.ecgData.enrollmentId,
          ecgViewer.ecgData.sequence
        );

        let midpoint = (ecgViewer.endSample + ecgViewer.startSample) / 2;
        if (scrollDirection === "down") {
          midpoint += scrollAmount;
        } else if (scrollDirection === "up") {
          midpoint -= scrollAmount;
        }
        ecgViewer.setBoundaries(midpoint);

        ecgViewer.drawLeads();

        // Shift Scrolling on the ECG viewer once the navbox has reached the end will scroll the sequence viewer
        this._moveViewerToIncludeStrip(
          midpoint,
          ecgViewer.ecgData.samplesPerSecond * ecgViewer.secondsToDisplay,
          sequenceViewer
        );
        // Update the position (middleX)
        updatedBox.middleX = this.getXCoordFromSample(sequenceViewer, midpoint);
        this._$rootScope.$emit("strip-viewer-updated");
        this._$rootScope.$emit("viewer-settings-updated");
        break;
      }

      case "sequence": {
        let scrollAmount = 300;
        const stripViewer = this._getStripViewer(ecgViewer.ecgData.enrollmentId, ecgViewer.ecgData.sequence);
        const fullViewer = this.getFullViewer(ecgViewer.ecgData.enrollmentId, ecgViewer.ecgData.sequence);

        if (scrollDirection === "down") {
          if (ecgViewer.endSample + scrollAmount > fullViewer.endSample) {
            scrollAmount -= ecgViewer.endSample + scrollAmount - fullViewer.endSample;

            /*
             * Sometimes a viewer end sample is off by 1 or more samples from other viewers, which allows
             * the viewers to get further out of sync when continuing to scroll at the edge.
             * This prevents scrolling to the right if the scroll amount would be less than 5 samples
             */
            if (scrollAmount < 5) {
              return false;
            }
          }
          ecgViewer.startSample += scrollAmount;
          ecgViewer.endSample += scrollAmount;
          stripViewer.startSample += scrollAmount;
          stripViewer.endSample += scrollAmount;
        } else if (scrollDirection === "up") {
          if (ecgViewer.startSample - scrollAmount < fullViewer.startSample) {
            scrollAmount -= Math.abs(ecgViewer.startSample - scrollAmount);
          }
          ecgViewer.startSample -= scrollAmount;
          ecgViewer.endSample -= scrollAmount;
          stripViewer.startSample -= scrollAmount;
          stripViewer.endSample -= scrollAmount;
        }

        ecgViewer.setBoundaries(ecgViewer.startSample + (ecgViewer.endSample - ecgViewer.startSample) / 2);
        stripViewer.setBoundaries(
          stripViewer.startSample + (stripViewer.endSample - stripViewer.startSample) / 2
        );

        ecgViewer.drawLeads();
        stripViewer.drawLeads();

        // Shift Scrolling on the Sequence viewer will move the strip if it is about to go off the screen
        this._moveStripInsideViewer(
          updatedBox,
          ecgViewer.ecgData.samplesPerSecond * stripViewer.secondsToDisplay,
          ecgViewer
        );
        this._$rootScope.$emit("viewer-settings-updated");
        break;
      }
      default:
      // do nothing
    }
    return false;
  }

  _mouseDownEvent(event, ecgViewer, boxes) {
    // Only respond to the primary mouse button
    if (event.button === 0) {
      ecgViewer.dragMode = true;
      const {x: mouseX} = this._getMousePosition(ecgViewer, event);
      this.mouseDownX = mouseX;
      this.hasMouseMoved = false;
      this.mouseOffsetX = 0;

      if (ecgViewer.config.type === "sequence") {
        const updatedBox = boxes.find((box) => box.isSelected) || boxes[0];
        const isInsideBox = this._isInsideBox(
          mouseX,
          ecgViewer,
          updatedBox.middleX,
          updatedBox.secondsWide,
          true
        );

        if (isInsideBox) {
          this._defaultNavigationCursor(ecgViewer);
          const canDrag =
            !updatedBox.id ||
            updatedBox.editing ||
            (ecgViewer.parentType === "stripViewer" && ecgViewer.inEditingMode);
          if (canDrag) {
            ecgViewer.navigationCanvas.classList.add("grabbing");
          }
          this.mouseOffsetX = updatedBox.middleX - mouseX;
        } else {
          ecgViewer.dragMode = false;
        }
      }
    }
  }

  _clickEvent(event, ecgViewer, boxes) {
    const {x: mouseX} = this._getMousePosition(ecgViewer, event);
    const updatedBox = boxes.find((box) => box.isSelected);
    const isInsideBox = this._isInsideBox(
      mouseX,
      ecgViewer,
      updatedBox.middleX,
      updatedBox.secondsWide,
      true
    );

    if (mouseX === this.mouseDownX && !this.hasMouseMoved) {
      this._defaultNavigationCursor(ecgViewer);
      ecgViewer.navigationCanvas.classList.add("grab");
      if (!isInsideBox) {
        this.mouseOffsetX = 0;
        this._synchronizeViews(ecgViewer, event, boxes);
      }
    }
  }

  _rightClickEvent(event, ecgViewer, boxes) {
    switch (ecgViewer.config.type) {
      case "sequence": {
        // Right Clicking on the Sequence Viewer brings up the menu to change the displayed lead
        // Selecting a lead for context Viewer is NOT allowed if this is an ECG Event and the strip already has an ID
        const selectedStrip = boxes.find((box) => box.isSelected);
        if (ecgViewer.allowEcgNavigationScroll && selectedStrip.id && !selectedStrip.editing) {
          return;
        }

        // The menu item is absolute positioning, not relative to the canvas
        const position = {
          clientY: event.clientY - this._Config.topNavOffset,
          clientX: event.clientX,
        };

        // Get the lead names
        const leadNames = ecgViewer.ecgData.leads.map((lead) => lead.leadName);

        // Filter out disabled leads- only add the lead name if it is enabled
        const selectableLeads = [];
        if (selectedStrip.displayedLeads.I) {
          selectableLeads.push({
            name: leadNames[0],
            value: "I",
          });
        }
        if (selectedStrip.displayedLeads.II) {
          selectableLeads.push({
            name: leadNames[1],
            value: "II",
          });
        }
        if (selectedStrip.displayedLeads.III) {
          selectableLeads.push({
            name: leadNames[2],
            value: "III",
          });
        }

        this._showLeadSelectMenu(ecgViewer.navigationCanvas, selectableLeads, position)
          .then((selectedOption) => {
            // Update Displayed Lead on the strip and redraw
            selectedStrip.sequenceLead = selectedOption;
            this._$rootScope.$emit("viewer-settings-updated");
          })
          .catch((err) => {
            // When the Menu is closed by clicking outside, an error is thrown but err is undefined.
            if (err) {
              throw err;
            }
          });
        break;
      }
      case "full":
        break;
      default:
        break;
    }
  }

  _showLeadSelectMenu(canvas, options, position) {
    return this._$mdDialog.show({
      controller: "LeadSelectMenuController",
      controllerAs: "leadSelectMenu",
      locals: {options, position},
      template: leadSelectMenuPug(),
      fullscreen: false,
      clickOutsideToClose: true,
      hasBackdrop: false,
      disableParentScroll: false,
      multiple: true, // This must be included on the child mdDialog when there are nested mdDialogs
    });
  }

  // This is for if the mouse is dragging outside the canvas:
  _mouseMoveEvent(event, ecgViewer, boxes) {
    this.hasMouseMoved = true;

    if (ecgViewer.config.type === "sequence") {
      const updatedBox = boxes.find((box) => box.isSelected) || boxes[0];
      const canDrag =
        updatedBox && // handle rare race condition where there are no boxes (drag not allowed)
        (!updatedBox.id ||
          updatedBox.editing ||
          (ecgViewer.parentType === "stripViewer" && ecgViewer.inEditingMode));
      this._defaultNavigationCursor(ecgViewer);

      if (canDrag) {
        const isMouseDown = event.buttons === 1;
        const insideBox = this._isInsideBox(
          this._getMousePosition(ecgViewer, event).x,
          ecgViewer,
          updatedBox.middleX,
          updatedBox.secondsWide,
          true
        );
        const grabMode = ecgViewer.dragMode || (insideBox && !isMouseDown);
        const state = (grabMode << 1) + isMouseDown;
        const cursorStates = [
          "pointer", // grabMode: false, isMouseDown: false
          "notAllowed", // grabMode: false, isMouseDown: true
          "grab", // grabMode: true, isMouseDown: false
          "grabbing", // grabMode: true, isMouseDown: true
        ];
        ecgViewer.navigationCanvas.classList.add(cursorStates[state]);
      }
    }

    if (ecgViewer.dragMode) {
      this._synchronizeViews(ecgViewer, event, boxes);
    }
  }

  // disable dragging
  _dragStartEvent(event) {
    event.preventDefault();
  }

  _mouseUpEvent(ecgViewer) {
    ecgViewer.dragMode = false;
  }

  _synchronizeViews(ecgViewer, event, boxes = []) {
    const updatedBox = boxes.find((box) => box.isSelected);
    // Strip is NOT editable if this is an ECG Event and the box already has an ID
    if (ecgViewer.allowEcgNavigationScroll && updatedBox.id && !updatedBox.editing) {
      return;
    }

    /*
     * This function is wrapped in requestAnimationFrame to only run as frequently as the
     * browser can render
     */
    this._$window.requestAnimationFrame(() => {
      const viewerType = ecgViewer.config.type;
      const {ecgData} = ecgViewer;
      const totalSamples = ecgData.samplesPerSecond * ecgViewer.secondsToDisplay;
      const totalPixels = ecgViewer.width;
      const stripViewer = this._getStripViewer(ecgData.enrollmentId, ecgData.sequence);
      const sequenceViewer = this.getSequenceViewer(ecgData.enrollmentId, ecgData.sequence);
      const {startSample} = ecgViewer;
      const mousePosition = this._getMousePosition(ecgViewer, event, this.mouseOffsetX);

      const stripSecondsWide = stripViewer.secondsToDisplay;
      const sequenceSecondsWide = sequenceViewer.secondsToDisplay;

      // In order do draw the nav box around the center of the clicked point without
      // allowing the nav box to go beyond the edges of a viewer,
      // we have to adjust the clicked point to the maximum/minimum center value that would
      // not allow the nav box to go beyond the edge of a viewer.
      if (viewerType === "full") {
        mousePosition.x = this._updateMouseX(mousePosition.x, ecgViewer, sequenceSecondsWide);
      } else if (viewerType === "sequence") {
        mousePosition.x = this._updateMouseX(mousePosition.x, ecgViewer, stripSecondsWide);
      }

      const middleSample = this._getSampleFromXCoord(mousePosition.x, totalSamples, totalPixels, startSample);

      stripViewer.setBoundaries(middleSample);
      stripViewer.drawLeads();

      stripViewer.drawMarkers();

      if (viewerType === "sequence") {
        sequenceViewer.drawGrid();
        sequenceViewer.drawLeads();
        // Update the position (middleX)
        updatedBox.middleX = mousePosition.x;
        this._$rootScope.$emit("strip-viewer-updated");
        this.drawNavigationBoxes(ecgViewer, boxes);
      } else if (viewerType === "full") {
        sequenceViewer.setBoundaries(middleSample);
        sequenceViewer.drawGrid();
        sequenceViewer.drawLeads();

        // Moving the navBox on the full viewer will move the strip if necessary to keep it visible
        this._moveStripInsideViewer(updatedBox, ecgData.samplesPerSecond * stripSecondsWide, sequenceViewer);
        this._$rootScope.$emit("viewer-settings-updated");
      }
    });
  }

  /**
   * Takes current mouse position, updates x to the minimum position that keeps the navigation box in bounds
   *
   * @param {Number}    mouseX - the x position of the mouse
   * @param {EcgViewer} ecgViewer     - the ecgViewer that the mouse is on
   * @param {number} secondsWide     - the number of seconds wide of the nav box
   *
   * @return {Number} updated mouse x position
   *
   */
  _updateMouseX(mouseX, ecgViewer, secondsWide) {
    const {startX, endX} = this._getNavigationBoxBoundaries(ecgViewer, mouseX, secondsWide);

    return (endX + startX) / 2;
  }

  _getNavigationBoxBoundaries(ecgViewer, middleX, secondsWide, allowOffScreen = false) {
    const {pixelsPerSecond} = ecgViewer;
    const minimumBoxWidth = 0;

    const canvasWidth = ecgViewer.width;
    const navBoxWidth = Math.round(pixelsPerSecond * secondsWide);
    const minEnd = navBoxWidth;
    const maxStart = canvasWidth - navBoxWidth;

    // Calculate start and end points based on corrected value for middleX
    let startX = middleX - navBoxWidth / 2;
    let endX = middleX + navBoxWidth / 2;

    // Don't go off the left side
    if (startX < 0) {
      startX = 0;
      if (!allowOffScreen) {
        endX = minEnd;
      } else if (endX < minimumBoxWidth) {
        endX = minimumBoxWidth;
      }
    }

    // Don't go off the right side
    if (endX > canvasWidth) {
      endX = canvasWidth;
      if (!allowOffScreen) {
        startX = maxStart;
      } else if (startX > canvasWidth - minimumBoxWidth) {
        startX = canvasWidth - minimumBoxWidth;
      }
    }

    return {startX, endX};
  }

  _isInsideBox(mouseX, ecgViewer, middleX, secondsWide, allowOffScreen) {
    const {startX, endX} = this._getNavigationBoxBoundaries(ecgViewer, middleX, secondsWide, allowOffScreen);
    return startX <= mouseX && mouseX <= endX;
  }

  /**
   * Used to keep navigation boxes on screen for long ECGs of more than one minute.
   * Should only be used on the selected strip. If the strip is off screen, this will
   * move it minimally to put it on screen.
   * @param {Object} strip to move
   * @param {Number} stripSamplesWide Number of samples in a strip (e.g. 2000 for 8 seconds)
   * @param {Object} sequenceViewer
   */
  _moveStripInsideViewer(strip, stripSamplesWide, sequenceViewer) {
    const stripHalfSamples = stripSamplesWide / 2;

    // Off the left side of the screen
    if (strip.middleSample - stripHalfSamples < sequenceViewer.startSample) {
      strip.middleSample = sequenceViewer.startSample + stripHalfSamples;
    }

    // Off the right side of the screen
    if (strip.middleSample + stripHalfSamples > sequenceViewer.endSample) {
      strip.middleSample = sequenceViewer.endSample - stripHalfSamples;
    }
  }

  /**
   * Used to keep navigation boxes on screen for long ECGs of more than one minute.
   * Should only be used on the selected strip. If the strip is outside the sequence viewer,
   * this will move the viewer minimally to put it on screen.
   * @param {Number} middleSample of the strip to include
   * @param {Number} stripSamplesWide Number of samples in a strip (e.g. 2000 for 8 seconds)
   * @param {Object} sequenceViewer
   */
  _moveViewerToIncludeStrip(middleSample, stripSamplesWide, sequenceViewer) {
    const stripHalfSamples = stripSamplesWide / 2;
    const stripStartSample = middleSample - stripHalfSamples;
    const stripEndSample = middleSample + stripHalfSamples;
    const sequenceViewerMiddleSample = (sequenceViewer.startSample + sequenceViewer.endSample) / 2;

    if (stripStartSample < sequenceViewer.startSample) {
      const amountToMoveToTheLeft = sequenceViewer.startSample - stripStartSample; // always positive
      sequenceViewer.setBoundaries(sequenceViewerMiddleSample - amountToMoveToTheLeft);
    }

    if (stripEndSample > sequenceViewer.endSample) {
      const amountToMoveToTheRight = stripEndSample - sequenceViewer.endSample; // always positive
      sequenceViewer.setBoundaries(sequenceViewerMiddleSample + amountToMoveToTheRight);
    }
  }

  _getStripViewer(enrollmentId, sequence) {
    return this._getViewersByEcg(enrollmentId, sequence).strip;
  }

  _getViewersByEcg(enrollmentId, sequence) {
    const viewerLink = this.viewerLinks.find((existingLink) => {
      return existingLink.enrollmentId === enrollmentId && existingLink.sequence === sequence;
    });
    return viewerLink?.viewers;
  }

  _getSampleFromXCoord(xCoord, totalSamples, totalPixels, startSample) {
    return startSample + Math.round((xCoord * totalSamples) / totalPixels);
  }

  _getXCoordFromSample(ecgViewer, sample) {
    const totalPixels = ecgViewer.width;
    const totalSamples = ecgViewer.ecgData.samplesPerSecond * ecgViewer.secondsToDisplay;
    const samplesPerPixel = totalPixels / totalSamples;
    const sampleNumberInViewer = sample - ecgViewer.startSample;

    return sampleNumberInViewer * samplesPerPixel;
  }

  _getMousePosition(ecgViewer, event, xOffset = 0) {
    const canvas = ecgViewer.navigationCanvas;
    const rect = canvas.getBoundingClientRect();

    // Correct for DPI scaling
    const canvasWidth = ecgViewer.width;
    const containerWidth = ecgViewer.navigationCanvas.scrollWidth;
    const correctedX = Math.round(((event.clientX - rect.left + xOffset) * canvasWidth) / containerWidth);
    const canvasHeight = ecgViewer.height;
    const containerHeight = ecgViewer.navigationCanvas.scrollHeight;
    const correctedY = Math.round(((event.clientY - rect.top) * canvasHeight) / containerHeight);

    return {
      x: correctedX,
      y: correctedY,
    };
  }

  _defaultNavigationCursor(ecgViewer) {
    ecgViewer.navigationCanvas.classList.remove("grab");
    ecgViewer.navigationCanvas.classList.remove("grabbing");
    ecgViewer.navigationCanvas.classList.remove("pointer");
    ecgViewer.navigationCanvas.classList.remove("notAllowed");
  }
}
