import pugFile from "./eventClassificationOption.pug";

export default function eventClassificationOptionDirective() {
  return {
    scope: {},
    controller: "EventClassificationOptionController",
    controllerAs: "eventClassificationOption",
    template: pugFile(),
    bindToController: {
      disabled: "=",
      buttonId: "@",
      name: "@",
      tooltip: "@",
    },
  };
}
