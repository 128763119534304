import pugFile from "./invertChannelButton.pug";

export default function invertChannelButtonDirective() {
  return {
    scope: {},
    controller: "InvertChannelButtonController",
    controllerAs: "invertButton",
    template: pugFile(),
    bindToController: {
      itemId: "@",
      buttonIndex: "<",
      channel: "=",
      ecgStripViewerConfig: "=",
      sequenceViewerConfig: "=",
      preventEditing: "=?",
    },
  };
}
