/* @ngInject */
export default class Leads {
  constructor(Drawing, Config) {
    this._Drawing = Drawing;
    this._features = Config.features;

    // Adding half values to correct for the Canvas Grid system
    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Applying_styles_and_colors#A_lineWidth_example
    this.CANVAS_LINE_OFFSET = 0.5;
    this.DEFAULT_GAIN = 10;
    this.DEFAULT_TIME_BASE = 25;
    this.DEFAULT_Y_AXIS_INVERSION = -1;

    this.leadColor = "black";
  }

  /**
   * Draws a lead
   *
   * @param {object}      lead
   * @param {number[]}    lead.samples
   * @param {number}      lead.startX
   * @param {number}      lead.startY
   * @param {number}      lead.width      - Pixel size of the entire width of the lead, based on
   *                                         zoom (pixelsPerSecond) levels. Could be full canvas,
   *                                         or smaller if lead is not long enough.
   * @param {HTMLElement} canvas
   * @param {number}      pixelsPerSecond
   *
   * @param {object}      ecgData
   * @param {number}      ecgData.avm     - Amplitude Value Multiplier. Used to get original
   *                                          microvolt value recorded by device
   */
  drawLead(lead, canvas, pixelsPerSecond, ecgData) {
    let {mmPerSecond} = ecgData;
    let {mmPerMillivolt} = ecgData;

    if (canvas.id?.startsWith("sequence-leads-canvas")) {
      mmPerMillivolt = this.DEFAULT_GAIN;
      mmPerSecond = this.DEFAULT_TIME_BASE;
    }
    const ctx = canvas.getContext("2d");
    const MIN_PERIOD = 1000; // microseconds (4000us = 250hz)
    const compressionFactor =
      ecgData.samplePeriod < MIN_PERIOD ? Math.round(MIN_PERIOD / ecgData.samplePeriod) : 1;
    const samples = this.getResizedLead(lead.data, lead.width, compressionFactor);
    const startX = lead.startX + this.CANVAS_LINE_OFFSET;
    const startY = lead.startY + this.CANVAS_LINE_OFFSET;
    const pixelsPerMillimeter = pixelsPerSecond / mmPerSecond;
    const pixelsPerMillivolt = pixelsPerMillimeter * mmPerMillivolt;

    ctx.strokeStyle = this.leadColor;
    ctx.lineWidth = 1;
    ctx.lineJoin = "round";
    ctx.moveTo(startX, startY);
    ctx.beginPath();

    samples.forEach((sample) => {
      const x = startX + sample.x;
      const nanovolts = sample.y * ecgData.avm;
      const millivolts = nanovolts / 1000000;
      // The default y axis inversion is -1 because Y axis ascends from top in canvas
      const y =
        startY +
        Math.round(millivolts * pixelsPerMillivolt) * (this.DEFAULT_Y_AXIS_INVERSION * lead.inversionValue);

      ctx.lineTo(x, y);
    });

    ctx.stroke();
  }

  /**
   * Draws leads
   *
   * @param {String}      viewerType   - the type of viewer that the leads are being drawn for
   * @param {object}      displayLeads - object containing leads with key = name, value = boolean
   * @param {object}      invertLeads - object containing leads with key = name, value = boolean
   * @param {HTMLElement} canvas
   * @param {object}      ecgData
   * @param {number}      pixelsPerSecond
   * @param {number}      startSample
   * @param {number}      endSample
   * @param {number}      unscaledCanvasWidth
   * @param {Boolean}     isGeneratedReport
   */
  drawLeads(
    viewerType,
    displayLeads,
    invertLeads,
    canvas,
    ecgData,
    pixelsPerSecond,
    startSample,
    endSample,
    unscaledCanvasWidth,
    isGeneratedReport = false
  ) {
    const slicedLeads = this.getSlicedLeads(ecgData.leads, startSample, endSample);

    const legendFontSize = isGeneratedReport ? 12 : 16;
    const labelFontSize = isGeneratedReport ? 12 : 18;

    this._Drawing.clearCanvas(canvas);

    if (viewerType === "sequence") {
      /*
       * Priority:
       *   displayedLeads: true (First occurrence by Object.keys)
       *   name === "Lead II"
       *   index === 1
       */
      let leadIndexToUse; // a lead from slicedLeads
      const leadIdentifier = Object.keys(displayLeads).find((key) => displayLeads[key]); // one of 'I', 'II', 'III'
      if (leadIdentifier !== undefined) {
        switch (leadIdentifier) {
          case "I":
            leadIndexToUse = 0;
            break;
          case "II":
            leadIndexToUse = 1;
            break;
          case "III":
            leadIndexToUse = 2;
            break;
          default:
        }
      }
      if (leadIndexToUse === undefined) {
        leadIndexToUse = slicedLeads.findIndex((lead) => lead.leadName === "Lead II");
      }
      if (leadIndexToUse === undefined) {
        leadIndexToUse = 1;
      }

      // If none of the above options are defined, no lead will be drawn
      const leadToUse = slicedLeads[leadIndexToUse];
      if (leadToUse !== undefined) {
        const indexOfSequenceLead = slicedLeads.indexOf(leadToUse);
        const drawLabel = this._calculateCoordinatesAndDrawLead(
          leadToUse,
          this._leadShouldBeInverted(invertLeads, indexOfSequenceLead),
          1,
          canvas,
          ecgData,
          pixelsPerSecond,
          labelFontSize
        );
        drawLabel();
      }
    } else {
      const labelDrawingFunctions = [];
      slicedLeads.forEach((lead, index) => {
        if (this._leadShouldBeDisplayed(displayLeads, index)) {
          const drawLabel = this._calculateCoordinatesAndDrawLead(
            lead,
            this._leadShouldBeInverted(invertLeads, index),
            index,
            canvas,
            ecgData,
            pixelsPerSecond,
            labelFontSize
          );
          labelDrawingFunctions.push(drawLabel);
        }
      });
      labelDrawingFunctions.forEach((drawLabel) => {
        drawLabel();
      });
      this.scaleLegend = `${ecgData.mmPerSecond}mm/s ${ecgData.mmPerMillivolt}mm/mV`;

      const marginRight = 10;
      const legendX = unscaledCanvasWidth - marginRight;
      const legendY = canvas.scrollHeight - 20;

      this.drawScaleLegend(canvas, legendX, legendY, this.scaleLegend, legendFontSize);
    }
  }

  /**
   * Creates X,Y pairs given Y values and pixel width
   *
   * @param  {array}   samples Array of numbers, index = x coordinate, value = y coordinate
   * @param  {integer} width   Desired width in pixels
   * @param  {integer} compressionFactor Down-sampling Factor (Nth Value Down-sampling)
   *
   * @return {array}      New array with normalized coordinates
   * @throws {RangeError} If width is smaller than 1
   */
  getResizedLead(samples, width, compressionFactor = 1) {
    if (width < 1) {
      throw new RangeError("Width must be greater than 0");
    }

    const coordinates = [];
    const ratio = width / samples.length;

    for (let i = 0; i < samples.length; i += compressionFactor) {
      const x = Math.round(i * ratio);
      const y = samples[i];
      coordinates.push({x, y});
    }

    return coordinates;
  }

  /**
   * Returns a section of a lead based on start and end samples
   *
   * @param  {array}   leads       - leadData
   * @param  {integer} startSample - First sample number, **inclusive.**
   * @param  {integer} endSample   - Last sample number, **exclusive**
   *
   * @return {array} A sliced version of the lead data object
   * @throws {RangeError}          If `lastSample < firstSample` ||
   *                               `firstSample < 0` ||
   *                               `lastSample > the last sample`.
   */
  getSlicedLeads(leads, startSample, endSample) {
    if (endSample < startSample || startSample < 0 || endSample > leads[0].data.length - 1) {
      throw new RangeError();
    }

    const slicedLeads = leads.map((lead) => {
      // Create a shallow copy of lead with a shallow copy of sliced data
      const data = lead.data.slice(startSample, endSample + 1);
      return {...lead, data};
    });

    return slicedLeads;
  }

  /**
   * Adds a label to a lead
   *
   * @param {HTMLElement} canvas - The canvas to draw the label on
   * @param {number}      labelY - The Y coordinate on the canvas to draw the label at
   * @param {string}      name - The text to draw for the label
   * @param {number}      fontSize - The font size of the label in pixels
   */
  drawLeadLabel(canvas, labelY, name, fontSize = 18) {
    const distanceFromLeft = 8;
    const displaySettings = {
      fontSize,
      fontFamily: "Roboto",
      color: "black",
      boxPadding: 3,
    };
    this._Drawing.drawText(canvas, distanceFromLeft, labelY, name, displaySettings);
  }

  /**
   * Adds a scale legend to a lead canvas
   *
   * @param {HTMLElement} canvas - The canvas to draw the label on
   * @param {number}      scaleLegendX - The X coordinate on the canvas to draw the text at
   * @param {number}      scaleLegendY - The Y coordinate on the canvas to draw the text at
   * @param {string}      textToDisplay - The text to draw for the scaleLegend
   * @param {number}      fontSize - The font size of the legend in pixels
   */
  drawScaleLegend(canvas, scaleLegendX, scaleLegendY, textToDisplay, fontSize = 16) {
    const displaySettings = {
      fontSize,
      fontFamily: "Roboto",
      color: "black",
      textAlign: "right",
    };
    this._Drawing.drawText(canvas, scaleLegendX, scaleLegendY, textToDisplay, displaySettings);
  }

  /**
   * Calculates the starting x and y coordinates to draw the lead at
   * then it draws the given lead with ecgData and the label name
   * @param {object} lead
   * @param {boolean} inversionStatus lead inversion status
   * @param {number} index
   * @param {object} canvas
   * @param {object} ecgData
   * @param {number} pixelsPerSecond
   * @param {number} fontSize in pixels
   * @private
   *
   * @returns {function} Function that will draw the lead's label
   */
  _calculateCoordinatesAndDrawLead(lead, inversionStatus, index, canvas, ecgData, pixelsPerSecond, fontSize) {
    const {samplesPerSecond} = ecgData;
    const labelOffset = Math.round(canvas.scrollHeight / 8);

    const startY = Math.round(((index + 1) * canvas.scrollHeight) / 4);
    const seconds = lead.data.length / samplesPerSecond;
    const width = Math.round(seconds * pixelsPerSecond);

    this.drawLead(
      {
        data: lead.data,
        inversionValue: this._getLeadInversionValue(inversionStatus),
        startX: 0,
        startY,
        width,
      },
      canvas,
      pixelsPerSecond,
      ecgData
    );

    const labelText = lead.leadName;
    let labelY = startY - labelOffset;
    if (canvas.id.startsWith("sequence")) {
      const displayedHeight = this._Drawing.calculateDisplayedHeight(labelText, fontSize);
      labelY = Math.floor((canvas.scrollHeight - displayedHeight) / 2);
    }
    return this.drawLeadLabel.bind(this, canvas, labelY, labelText, fontSize);
  }

  /**
   * Returns if the lead number should be displayed or not
   * @param {object} displayLeads
   * @param {boolean} displayLeads.I
   * @param {boolean} displayLeads.II
   * @param {boolean} displayLeads.III
   * @param {number} leadNumber
   * @returns {boolean}
   * @private
   */
  _leadShouldBeDisplayed(displayLeads, leadNumber) {
    switch (leadNumber) {
      case 0:
        return displayLeads.I;
      case 1:
        return displayLeads.II;
      case 2:
        return displayLeads.III;
      default:
        return false;
    }
  }

  /**
   * Returns if the lead number should be inverted or not
   * @param {object} invertLeads
   * @param {boolean} invertLeads.I
   * @param {boolean} invertLeads.II
   * @param {boolean} invertLeads.III
   * @param {number} leadNumber
   * @returns {boolean}
   * @private
   */
  _leadShouldBeInverted(invertLeads, leadNumber) {
    switch (leadNumber) {
      case 0:
        return invertLeads.I;
      case 1:
        return invertLeads.II;
      case 2:
        return invertLeads.III;
      default:
        return false;
    }
  }

  /**
   * Returns 1 or -1 depending on the inversion status for the lead
   * @param {boolean} invertLead
   * @returns {number}
   * @private
   */
  _getLeadInversionValue(invertLead) {
    // If the lead should be inverted, -1 should be returned so that the y axis is flipped.
    if (invertLead) {
      return -1;
    }
    // If the lead should not be inverted, 1 should be returned so that the y axis remains the same.
    return 1;
  }
}
