import pugFile from "./stripMeasurementTable.pug";

export default function stripMeasurementTableDirective() {
  return {
    restrict: "E",
    scope: {},
    controller: "StripMeasurementTableController",
    controllerAs: "stripMeasurementTable",
    bindToController: {
      ecgEventItem: "=",
    },
    template: pugFile(),
  };
}
