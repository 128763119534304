/* @ngInject */
export default class StripMeasurementTableController {
  constructor(Markers) {
    this._Markers = Markers;
    // this.ecgEventItem is passed from directive attributes
    // and aren't available until $onInit
    this.$onInit = this._init;
  }

  /// Public Functions ///

  get selectedStrip() {
    // Inside the stripViewer, ecgEventItem is the currentStrip
    let selectedStrip = this.ecgEventItem;
    if (this.ecgEventItem.eventStrips && this.ecgEventItem.eventStrips.length > 0) {
      // Inside an ecgEventItem, eventStrips is an array.
      selectedStrip = this.ecgEventItem.eventStrips.find((savedStrip) => savedStrip.isSelected);
      if (!selectedStrip) {
        [selectedStrip] = this.ecgEventItem.eventStrips;
      }
    }
    return selectedStrip;
  }

  /* Assuming measurements are saved on the strip as
   * {
   *   RR: {
   *     min: 0,
   *     mean: 0,
   *     max: 0,
   *     data: []
   *   },
   *   ...
   * }
   * Where data is an array of objects with the following
   * {
   *   << id, >> // could not be on measurement if new
   *   name: 'RR',
   *   stripId: '123231',
   *   facilityId: '234234',
   *   startSampleOffset,
   *   endSampleOffset,
   *   startTime,
   *   endTime
   * }
   */
  getMeasurementCount(measurementType) {
    return this._Markers.getMeasurementCount(measurementType, this.selectedStrip);
  }

  getMeasurementMinMeanMax(measurementType) {
    return this._Markers.getMeasurementMinMeanMax(measurementType, this.selectedStrip);
  }

  /// Private Functions ///

  _init() {}
}
