/* @ngInject */
export default class GridLines {
  constructor(Drawing) {
    this._Drawing = Drawing;

    this.MM_PER_TIME_MARKER = 25;
    this.MM_PER_LARGE_BOX = 5;
    this.MM_PER_SMALL_BOX = 1;
    this.MAJOR_VERTICAL_COUNT = this.MM_HORIZONTAL / this.MM_PER_LARGE_BOX;
    this.MINOR_VERTICAL_COUNT = this.MM_HORIZONTAL / this.MM_PER_SMALL_BOX;
    this.MAJOR_HORIZONTAL_COUNT = this.MM_ACROSS / this.MM_PER_LARGE_BOX;
    this.MINOR_HORIZONTAL_COUNT = this.MM_ACROSS / this.MM_PER_SMALL_BOX;

    // Adding half values to correct for the Canvas Grid system
    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Applying_styles_and_colors#A_lineWidth_example
    this.CANVAS_LINE_OFFSET = 0.5;

    this.majorGridLineColor = "rgb(255, 225, 225)";
    this.minorGridLineColor = "rgb(255, 245, 245)";
    this.timeMarkerGridLineColor = "rgb(212, 138, 138)";
  }

  clearCanvas(canvas) {
    this._Drawing.clearCanvas(canvas);
  }

  /**
   * Draws the major grid lines (large boxes - 5mm) on the canvas
   *
   * @param {HTMLElement} canvas              - the canvas that a the lines should be drawn on
   * @param {number}      totalWidthMm        - Number of millimeters across
   * @param {number}      totalHeightMm       - Number of millimeters high
   * @param {number}      pixelsPerMillimeter - Number of pixels in one millimeter
   */
  drawMajorGridLines(canvas, totalWidthMm, totalHeightMm, pixelsPerMillimeter) {
    const verticalCount = totalWidthMm / this.MM_PER_LARGE_BOX;
    const horizontalCount = totalHeightMm / this.MM_PER_LARGE_BOX;
    const pixelsBetween = pixelsPerMillimeter * this.MM_PER_LARGE_BOX;
    const color = this.majorGridLineColor;

    this._drawVerticalLines(canvas, pixelsBetween, verticalCount, color);
    this._drawHorizontalLines(canvas, pixelsBetween, horizontalCount, color);
  }

  /**
   * Draws the minor grid lines (small boxes - 1mm) on the canvas
   *
   * @param {HTMLElement} canvas              - the canvas that a the lines should be drawn on
   * @param {number}      totalWidthMm        - Number of millimeters across
   * @param {number}      totalHeightMm       - Number of millimeters high
   * @param {number}      pixelsPerMillimeter - Number of pixels in one millimeter
   */
  drawMinorGridLines(canvas, totalWidthMm, totalHeightMm, pixelsPerMillimeter) {
    const verticalCount = totalWidthMm / this.MM_PER_SMALL_BOX;
    const horizontalCount = totalHeightMm / this.MM_PER_SMALL_BOX;
    const pixelsBetween = pixelsPerMillimeter * this.MM_PER_SMALL_BOX;
    const color = this.minorGridLineColor;

    this._drawVerticalLines(canvas, pixelsBetween, verticalCount, color);
    this._drawHorizontalLines(canvas, pixelsBetween, horizontalCount, color);
  }

  /**
   * Draws a vertical line every 1 second
   *
   * @param {HTMLElement} canvas              - the canvas that the lines should be drawn on
   * @param {number}      totalWidthMm        - Number of millimeters across
   * @param {number}      pixelsPerMillimeter - Number of pixels in one millimeter
   */
  drawTimeMarkerGridLines(canvas, totalWidthMm, pixelsPerMillimeter) {
    const verticalCount = totalWidthMm / this.MM_PER_TIME_MARKER;
    const pixelsBetween = pixelsPerMillimeter * this.MM_PER_TIME_MARKER;
    const color = this.timeMarkerGridLineColor;

    this._drawVerticalLines(canvas, pixelsBetween, verticalCount, color);
  }

  /**
   * Draws a set number of horizontal lines at a given spacing between lines
   *
   * @param {HTMLElement} canvas - the canvas that the lines should be drawn on
   * @param {number}      margin - pixels between each line
   * @param {number}      count  - number of pixels in one second
   * @param {string}      color  - color to use when drawing lines
   * @private
   */
  _drawHorizontalLines(canvas, margin, count, color) {
    for (let i = 0; i < count - 1; i++) {
      const y = (i + 1) * margin + this.CANVAS_LINE_OFFSET;
      const line = {
        startX: 0,
        startY: y,
        endX: canvas.clientWidth,
        endY: y,
        color,
        width: 1,
      };
      this._Drawing.drawLine(canvas, line);
    }
  }

  /**
   * Draws a set number of vertical lines at a given spacing between lines
   *
   * @param {HTMLElement} canvas - the canvas that the lines should be drawn on
   * @param {number}      margin - pixels between each line
   * @param {number}      count  - number of pixels in one second
   * @param {string}      color  - color to use when drawing lines
   * @private
   */
  _drawVerticalLines(canvas, margin, count, color) {
    for (let i = 0; i < count - 1; i++) {
      const x = (i + 1) * margin + this.CANVAS_LINE_OFFSET;
      const line = {
        startX: x,
        startY: 0,
        endX: x,
        endY: canvas.clientHeight,
        color,
        width: 1,
      };

      this._Drawing.drawLine(canvas, line);
    }
  }
}
