import pugFile from "./stripTools.pug";

export default function stripToolsDirective() {
  return {
    restrict: "E",
    scope: {},
    controller: "StripToolsController",
    controllerAs: "stripTools",
    bindToController: {
      ecgData: "=",
      ecgEventItem: "=",
      ecgChannels: "=",
      ecgStripViewerConfig: "=",
      sequenceViewerConfig: "=",
      stripClassifications: "=",
      selectedStripClassification: "=",
      stripComments: "=",
      saveStripForm: "=",
      preventEditing: "=?",
    },
    template: pugFile(),
  };
}
