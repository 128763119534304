import pugFile from "./displayChannelToggle.pug";

export default function displayChannelToggleDirective() {
  return {
    scope: {},
    controller: "DisplayChannelToggleController",
    controllerAs: "channelToggle",
    template: pugFile(),
    bindToController: {
      itemId: "@",
      toggleIndex: "<",
      channel: "=",
      allChannels: "<",
      leadName: "@",
      ecgStripViewerConfig: "=",
      selectedStrip: "=",
      preventEditing: "=?",
    },
  };
}
