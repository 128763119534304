/* @ngInject */
export default class LeadSelectMenuController {
  constructor($mdDialog, options, position) {
    this._$mdDialog = $mdDialog;
    this.options = options;
    this.position = position;
    this.style = {
      position: "absolute",
      top: `${position.clientY}px`,
      left: `${position.clientX}px`,
    };
  }

  selectOption(option) {
    return this._$mdDialog.hide(option);
  }
}
