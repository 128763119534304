export default function ecgViewerDirective() {
  return {
    scope: {},
    controller: "EcgViewerController",
    controllerAs: "ecgViewer",
    bindToController: {
      viewerConfig: "=",
      ecgData: "=",
      allowEcgNavigationScroll: "=",
      navBoxes: "=?",
      beatMarkers: "=?",
      aspectRatio: "=?",
      parentType: "=?",
    },
  };
}
