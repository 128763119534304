/* @ngInject */
export default class MarkerMenuController {
  constructor($mdDialog, options, position, selectedMarker) {
    this.selectedMarker = selectedMarker;
    this._$mdDialog = $mdDialog;
    this.options = options;
    this.position = position;
    this.style = {
      position: "absolute",
      top: `${position.clientY}px`,
      left: `${position.clientX}px`,
    };
  }

  get deleteOrCancel() {
    return this.selectedMarker ? "Delete" : "Cancel";
  }

  selectOption(option) {
    return this._$mdDialog.hide(option);
  }

  clickedDelete() {
    if (this.selectedMarker) {
      return this._$mdDialog.hide("delete");
    }
    return this._$mdDialog.cancel();
  }
}
