/* eslint-env browser */
import queryString from "qs";

/* @ngInject */
export default class ExcludeArtifactService {
  constructor($injector) {
    this._$injector = $injector;
    this._Config = $injector.get("Config");
    this._$http = $injector.get("$http");
    this._features = this._Config.features;
    this._Authentication = $injector.get("Authentication");
    this._$rootScope = $injector.get("$rootScope");
  }

  init(graphData) {
    this.regionsToDelete = []; // Array of uuid
    this.regions = graphData.regions;
    this.MIN_ARTIFACT_REGION = 4; // pixels
    this.drawRegions(graphData);
  }

  /**
   * Checks if the coordinates are inside the plot area, as determined by the graph dimensions and margins
   * @param {Object} graphData
   * @param {Object} graphData.margin top, bottom, left, and right margin information
   * @param {Number} graphData.width
   * @param {Number} graphData.height
   * @param {Array} coordsArray [X, Y] coordinates to check
   * @returns {Boolean}
   */
  isInsidePlotArea(graphData, coordsArray) {
    const x = coordsArray[0];
    const y = coordsArray[1];
    const insideXDimension = x >= graphData.margin.left && x <= graphData.width - graphData.margin.right;
    const insideYDimension = y > graphData.margin.top && y < graphData.height - graphData.margin.bottom;
    return insideXDimension && insideYDimension;
  }

  /**
   * Draws artifact regions on the SVG. Will also draw a previewing region if included.
   * @param {Object} graphData
   * @param {Object} graphData.svg to draw on
   * @param {Array} graphData.regions Array containing regions, where units are in pixels
   *                      For Example:
   *                      [
   *                        {startX: 230, endX: 510, hitbox: {}},
   *                        {startX: 1400, endX: 1680, hitbox: {}}
   *                      ]
   * @param {Number} previewX1 Coordinates for the preview region (optional)
   * @param {Number} previewX2 Coordinates for the preview region (optional)
   */
  drawRegions(graphData, previewX1 = null, previewX2 = null) {
    // Clear the Rectangle and X icons from the SVG
    graphData.svg.selectAll(".rawArtifactRegion").remove();
    graphData.svg.selectAll(".rawArtifactBorder").remove();
    graphData.svg.selectAll(".rawArtifactIcon").remove();
    graphData.svg.selectAll(".rawArtifactIconHovered").remove();

    // Draw the Established regions
    graphData.regions.forEach((region) => {
      this._drawRegion(graphData, region);
    });

    // If included, draw preview region, without X icon
    if (previewX1 !== null && previewX2 !== null) {
      this._drawRegion(graphData, this._formatRegion(graphData, previewX1, previewX2), false);
    }
  }

  /**
   * Adds a region to the array of regions, and combines any regions that overlap
   * @param {Object} graphData
   * @param {Array} graphData.regions
   * @param {Number} x1 Coordinates for the new region
   * @param {Number} x2 Coordinates for the new region
   */
  addRegion(graphData, x1, x2) {
    const regionToAdd = this._formatRegion(graphData, x1, x2);
    const {regions} = graphData;
    regions.push(regionToAdd);

    let overlappingPair;
    do {
      overlappingPair = this._findOverlappingPair(regions);
      if (overlappingPair) {
        const regionA = regions[overlappingPair[0]];
        const regionB = regions[overlappingPair[1]];

        // Combine regions into regionB
        regionB.startTime = Math.min(regionA.startTime, regionB.startTime);
        regionB.endTime = Math.max(regionA.endTime, regionB.endTime);

        // Update the x coordinates
        regionB.startX = graphData.xScale(Math.max(regionB.startTime, graphData.domainStartTime));
        regionB.endX = graphData.xScale(Math.min(regionB.endTime, graphData.domainEndTime));

        // Mark regionB as "new", tracking id to delete if it has id
        if (regionB.id) {
          this.regionsToDelete.push(regionB.id);
          delete regionB.id;
        }

        // Delete regionA
        this.deleteRegion(graphData, overlappingPair[0]);
      }
    } while (overlappingPair);
    this.drawRegions(graphData);
  }

  /**
   * Finds the index of the region that has a X icon hitbox containing the mouse coordinates.
   * Returns -1 if none of the regions apply.
   * @param {Array} regions
   * @param {Array} coordsArray [X, Y] coordinates to check
   * @returns {Number}
   */
  findIndexOfClickedRegion(regions, coordsArray) {
    // Find a region with a hitbox containing the mouse
    return regions.findIndex((region) => {
      const {hitbox} = region;
      const dx = hitbox.cx - coordsArray[0];
      const dy = hitbox.cy - coordsArray[1];
      return Math.sqrt(dy * dy + dx * dx) < hitbox.radius;
    });
  }

  /**
   * Deletes a region
   * @param {Object} graphData
   * @param {Number} regionToDeleteIndex
   */
  deleteRegion(graphData, regionToDeleteIndex) {
    const regionToDelete = graphData.regions[regionToDeleteIndex];

    // Track deleted region
    if (regionToDelete.id) {
      this.regionsToDelete.push(regionToDelete.id);
    }

    // Remove from array
    graphData.regions.splice(regionToDeleteIndex, 1);

    // Redraw regions
    this.drawRegions(graphData);
  }

  save(enrollmentId) {
    const facilityId = this._Authentication.getFacilityId();
    const regionsToCreate = [];
    this.regions.forEach((region) => {
      if (!region.id) {
        regionsToCreate.push({
          startTime: new Date(region.startTime),
          endTime: new Date(region.endTime),
          enrollmentId,
          facilityId,
        });
      }
    });

    return Promise.all(this.regionsToDelete.map((id) => this.deleteArtifactRegion(id)))
      .then(() => this.createArtifactRegions(enrollmentId, regionsToCreate))
      .then(() => {
        if (regionsToCreate.length > 0 || this.regionsToDelete.length > 0) {
          // If there were any regions added or deleted, the card will need to be refetched.
          this._$rootScope.$emit("artifact-regions-updated");
        }
        return Promise.resolve();
      });
  }

  /**
   * Merges overlapping regions and regions that "meet" with a difference of zero ms
   *
   * creates a new array
   *
   * @param {Array} artifactRegions Array of regions
   * @param {Boolean} isSorted optional flag to forego sorting if the array is already sorted
   * @returns {Array} merged array
   */
  mergeRegions(artifactRegions, isSorted = false) {
    if (!isSorted) {
      // Sorting is required for this merge algorithm to work
      artifactRegions.sort((a, b) => a.startTime - b.startTime);
    }

    const mergedArtifactRegions = [];
    let accumulator = null;
    artifactRegions.forEach((region) => {
      if (!accumulator) {
        accumulator = region;
      } else if (region.startTime <= accumulator.endTime) {
        // If regions overlap, combine into the accumulator by setting accumulator.endTime to larger of the two
        if (region.endTime > accumulator.endTime) {
          accumulator.endTime = region.endTime;
        }
      } else {
        // Regions do not overlap so move on to the next region
        mergedArtifactRegions.push(accumulator);
        accumulator = region;
      }
    });

    if (accumulator) {
      mergedArtifactRegions.push(accumulator);
    }

    return mergedArtifactRegions;
  }

  getArtifactRegions(searchParameters) {
    const url = "/qrsExclusionRegions";
    return this._httpGet(url, searchParameters).then((response) => response.data);
  }

  getArtifactRegionsForStudy(studyId, searchParameters) {
    const url = `/qrsExclusionRegions/${studyId}`;
    return this._httpGet(url, searchParameters).then((response) => response.data);
  }

  createArtifactRegions(enrollmentId, regions) {
    const url = `/qrsExclusionRegions/bulk/${enrollmentId}`;
    if (regions.length > 0) {
      return this._httpPost(url, regions);
    }
    return Promise.resolve();
  }

  deleteArtifactRegion(id) {
    const url = `/qrsExclusionRegions/${id}`;
    return this._httpDelete(url);
  }

  /**
   * Creates a region based on the X coordinates. StartX will always be less than endX,
   * and the coordinates will be within the bounds of the graph.
   * {
   *    startX: Number,
   *    endX: Number,
   *    startTime: UNIX timestamp,
   *    endTime: UNIX timestamp,
   *    hitbox: {}
   * }
   *
   * @param {Object} graphData
   * @param {Number} x1
   * @param {Number} x2
   * @returns {Object}
   */
  _formatRegion(graphData, x1, x2) {
    let startX = Math.min(x1, x2);
    let endX = Math.max(x1, x2);
    let startTime;
    let endTime;

    startTime = graphData.getTime(startX);
    // If outside bounds, take domainStart or domainEnd
    if (startTime < graphData.domainStartTime) {
      startTime = graphData.domainStartTime;
    }
    startX = graphData.xScale(startTime);
    endTime = graphData.getTime(endX);
    if (endTime > graphData.domainEndTime) {
      endTime = graphData.domainEndTime;
    }
    endX = graphData.xScale(endTime);

    return {
      startX,
      endX,
      startTime,
      endTime,
      hitbox: {},
    };
  }

  /**
   * Checks if two regions are overlapping
   * @param {Object} r1
   * @param {Object} r2
   * @returns {Boolean}
   */
  _isOverlapping(r1, r2) {
    const a = r1.startTime <= r2.startTime && r2.startTime <= r1.endTime;
    const b = r1.startTime <= r2.endTime && r2.endTime <= r1.endTime;
    const c = r2.startTime <= r1.startTime && r1.startTime <= r2.endTime;
    const d = r2.startTime <= r1.endTime && r1.endTime <= r2.endTime;
    return a || b || c || d;
  }

  /**
   * Compares every pair of regions until the first overlapping pair is found.
   * Returns an array of the indices or null is there are no overlapping regions
   * @param {Array<Object>} regions Array of regions to search through
   * @returns {Array|null}
   */
  _findOverlappingPair(regions) {
    for (let i = 0; i < regions.length; i++) {
      for (let j = i + 1; j < regions.length; j++) {
        if (this._isOverlapping(regions[i], regions[j])) {
          return [i, j];
        }
      }
    }
    return null;
  }

  /**
   * Draws a region on the SVG based on its coordinates.
   * @param {Object} graphData
   * @param {Object} region
   * @param {Boolean} withDeleteButton
   */
  _drawRegion(graphData, region, withDeleteButton = true) {
    const width = region.endX - region.startX;
    graphData.svg
      .append("rect")
      .attr("x", region.startX)
      .attr("y", graphData.margin.top)
      .attr("width", width)
      .attr("height", graphData.plotArea.height)
      .classed("rawArtifactRegion", true);

    // Outlines (Drawn separately in case the region overflows to the left or right)
    // Top Border
    graphData.svg
      .append("path")
      .attr("d", `M ${region.startX} ${graphData.margin.top} l ${width} 0`)
      .classed("rawArtifactBorder", true);

    // Bottom Border
    graphData.svg
      .append("path")
      .attr("d", `M ${region.startX} ${graphData.margin.top + graphData.plotArea.height} l ${width} 0`)
      .classed("rawArtifactBorder", true);

    // Left Border
    const arrowY = graphData.margin.top + graphData.plotArea.height / 2; // Y-value of the center of the arrow
    const arrowSize = 12; // Width of the arrow (Height = 2 * Width)
    const arrowPadding = 4;
    let leftSideData = `M ${region.startX} ${graphData.margin.top} l 0 ${graphData.plotArea.height}`;
    if (region.startTime < graphData.domainStartTime) {
      // Draw Left Arrow on Left side of graph instead of the border
      const arrowX = region.startX + arrowSize / 2 + arrowPadding;
      leftSideData = `M ${arrowX - arrowSize / 2} ${arrowY} l ${arrowSize} ${arrowSize}`;
      leftSideData += ` M ${arrowX - arrowSize / 2} ${arrowY} l ${arrowSize} -${arrowSize}`;
    }
    graphData.svg.append("path").attr("d", leftSideData).classed("rawArtifactBorder", true);

    let rightSideData = `M ${region.startX + width} ${graphData.margin.top} l 0 ${graphData.plotArea.height}`;
    if (region.endTime > graphData.domainEndTime) {
      // Draw Right Arrow on Right side of graph instead of the border
      const arrowX = region.endX - arrowSize / 2 - arrowPadding;
      rightSideData = `M ${arrowX + arrowSize / 2} ${arrowY} l -${arrowSize} ${arrowSize}`;
      rightSideData += ` M ${arrowX + arrowSize / 2} ${arrowY} l -${arrowSize} -${arrowSize}`;
    }
    graphData.svg // Right Border
      .append("path")
      .attr("d", rightSideData)
      .classed("rawArtifactBorder", true);

    if (withDeleteButton) {
      const padding = 4;
      const radius = 10;
      const iconWidth = 8;
      const cy = graphData.margin.top + radius + padding;
      let cx = region.endX - radius - padding;

      // Center the button if region is too narrow
      if (region.endX - region.startX < 2 * (radius + padding)) {
        cx = (region.endX + region.startX) / 2;
      }

      region.hitbox = {cx, cy, radius};
      graphData.svg
        .append("circle")
        .attr("cx", cx)
        .attr("cy", cy)
        .attr("r", radius)
        .attr("startX", region.startX)
        .attr("endX", region.endX)
        .classed("rawArtifactIconHovered", region.isHovered)
        .classed("rawArtifactIcon", !region.isHovered);

      // Custom path for the X icon
      // m is moveto, capital means absolute coordinates
      // l is lineto, lowercase means relative coordinates
      let xData = `M ${cx - iconWidth / 2} ${cy - iconWidth / 2} l ${iconWidth} ${iconWidth}`;
      xData += ` M ${cx - iconWidth / 2} ${cy + iconWidth / 2} l ${iconWidth} -${iconWidth}`;
      graphData.svg
        .append("path")
        .attr("d", xData)
        .classed("rawArtifactIconHovered", region.isHovered)
        .classed("rawArtifactIcon", !region.isHovered);
    }
  }

  _httpGet(url, params = {}) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpPost(url, body) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.post(`${baseUrl}${url}`, body, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpDelete(url) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$http.delete(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
